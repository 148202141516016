<template>
  <div class="d-flex justify-content-center align-items-center  ">
    <div class="mr-2 clickable" @click="toggle" style="cursor: pointer;">
      <v-icon class="icon"  size="25">{{
        value == null ? 'mdi-minus-box-outline' : value ? 'mdi-checkbox-intermediate' : 'mdi-checkbox-blank-outline'
      }}</v-icon>
    </div>
    <div>{{ label }}</div>
  </div>
</template>

<script>
export default {
  props: ['value', 'label'],
  methods: {
    toggle() {
      if (this.value === null || this.value === undefined) {
        this.$emit('input', true)
      } else if (this.value === true) {
        this.$emit('input', false)
      } else if (this.value === false) {
        this.$emit('input', null)
      }
    },
  },
}
</script>
<style scoped>
.icon:hover {
  color:azure ;
}
</style>
