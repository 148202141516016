<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-card>
        <div class="pa-3">
          <div class="d-flex align-center justify-space-between">
            <div class="text-h6">{{ titleConfig }}</div>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
        <div class="overflow-y-auto" style="height: 500px;">
          <v-list flat subheader two-line>
            <v-list-item-group v-model="settings" multiple>
              <template v-for="(data, ind) in listAllDatas">
                <v-list-item :value="data.value"  :key="data.value">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active" color="primary"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-subtitle>{{ data.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <div :key="data.name" class="px-3">
                  <v-divider />
                </div>
              </template>
            </v-list-item-group>
          </v-list>
        </div>
        <v-card-actions class="pa-2">
          <v-layout>
            <v-spacer />
            <v-btn :disabled="loading" class="mx-2" color="secondary" rounded type="reset" @click="dialog = false">
              Close
            </v-btn>
            <v-btn :loading="loading" color="primary" rounded type="submit" @click="submitData">
              Submit
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: {
    listAllDatas: { type: Array, default: () => [] },
    titleConfig: { type: String, default: 'Config' },
    listData: { type: Array, default: () => []}
  },
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    rules: {
      feildRequired: value => !!value || 'Feild is required',
    },
    uuid: null,
    settings: []
  }),
  watch: {
    listData: {
      handler: function (val) {
        this.settings = val
      },
      immediate: true
    }
  },
  methods: {
    async showCreate(uuid) {
      this.dialog = true
      if (this.$refs.formData) {
        this.$refs.formData.resetValidation()
      }
      this.settings = this.listData
      this.$emit('getData', uuid)
      this.uuid = uuid
    },

    async submitData() {
      try {
        this.$refs.formData.validate()
        if (!this.valid) return
        this.loading = true
        const params = {
          uuid: this.uuid,
          datas: [...new Set(this.settings)]
        }
        await this.$emit('updateData', params)
        this.dialog = false
      } catch (e) {
        console.warn('Error when submit data config: ', e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
