<template>
  <v-dialog v-model="dialog" persistent width="700">
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-card>
        <div class="pa-3">
          <div class="d-flex align-center justify-space-between">
            <div class="text-h6">Config Setting</div>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
        <v-tabs v-model="activeTab" grow>
          <v-tab v-for="(value, key) in settings" :key="key" @click="activeTab = key">
            {{ key }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="activeTab"
          style="min-height: 450px!important;overflow-y: auto;max-height: calc(100vh - 300px);"
        >
          <v-tab-item v-for="(setting, key) in settings" :key="key">
            <div v-if="key == 'string'">
              <div v-for="(value, key) in setting" :key="key" class="ma-5">
                <div class="d-flex mt-2">
                  <v-text-field class="mr-2" dense :label="value" outlined v-model="formData[value]"></v-text-field>
                </div>
              </div>
            </div>
            <div v-if="key == 'bool'">
              <div v-for="(value, key) in setting" :key="key" class="ma-5">
                <div class="d-flex mt-2">
                  <div>
                    <TriStateCheckBoxVue :label="value" v-model="formData[value]" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="key == 'array'">
              <div v-for="(value, key) in setting" :key="key" class="ma-5">
                <div class="d-flex mt-2">
                  <v-combobox
                    :label="value"
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    outlined
                    v-model="formData[value]"
                  ></v-combobox>
                </div>
              </div>
            </div>
            <div v-if="key == 'json'">
              <div v-for="(value, key) in setting" :key="key" class="ma-5">
                <div class="d-flex mt-2 json-edit">
                  <div>
                    <span class="mr-2">{{ value }}</span>
                    <v-icon class="icon ma-2" @click="showEditor[value] = !showEditor[value]">
                      {{
                        showEditor[value] ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline'
                      }}</v-icon
                    >
                  </div>

                  <div style="height: 400px;" v-if="showEditor[value]">
                    <vue-monaco-editor
                      language="json"
                      v-model:value="json[value]"
                      :options="options"
                      theme="vs-dark"
                      @change="updateFormData(value, $event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-layout>
            <v-spacer />
            <v-btn :disabled="loading" class="mx-2" color="secondary" rounded type="reset" @click="dialog = false">
              Close
            </v-btn>
            <v-btn :loading="loading" color="primary" rounded type="submit" @click="submitData">
              Submit
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { updateSettingUser } from '@/api/user'
import { updateSettingOrganization } from '@/api/organization'

import TriStateCheckBoxVue from './TriStateCheckBox.vue'
export default {
  name: 'SettingConfig',
  components: { TriStateCheckBoxVue },
  props: {
    settings: { type: Object, default: () => {} },
    type: { type: String },
  },
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    activeTab: null,
    rules: {
      feildRequired: value => !!value || 'Feild is required',
    },
    formData: {},
    item: null,
    showEditor: {},
    options: {
      automaticLayout: true,
      formatOnType: true,
      formatOnPaste: true,
    },
    json: { custom_indices: '{"indices":[]}' },
    jsonValidate: true,
    errors: [],
  }),
  watch: {
    json: {
      handler(newVal, oldVal) {
        for (let key in newVal) {
          try {
            this.formData[key] = JSON.parse(newVal[key])

            this.errors = this.errors.filter(error => error.key !== key)
          } catch (error) {
            if(newVal[key]!== undefined){
              let jsonError = { key: key, error: error }
            if (this.errors.filter(error => error.key === key).length === 0) {
              this.errors.push(jsonError)
            }
            }
            
          }
        }
        if (this.errors.length > 0) {
          this.jsonValidate = false
        } else {
          this.jsonValidate = true
        }
      },
      deep: true,
    },
  },
  methods: {
    updateFormData(key, newValue) {
      try {
        this.formData[key] = JSON.parse(newValue)
      } catch (error) {}
    },
    initializeFormData() {
      let formData = {}

      for (let key in this.settings) {
        this.settings[key].forEach(value => {
          formData[value] =
            this.item && this.item.setting && this.item.setting[value] !== undefined
              ? this.item.setting[value]
              : undefined
          this.$set(this.showEditor, value, false)
        })
      }
      for (let key in this.settings['json']) {
        this.json[this.settings['json'][key]] = JSON.stringify(this.item.setting[this.settings['json'][key]], null, 2)
      }
      return formData
    },
    async showDialog(item) {
      this.item = item
      this.dialog = true
      this.formData = this.initializeFormData()
    },
    async submitData() {
    
      if (this.jsonValidate === false) {
    
        this.$store.commit('message/SHOW_ERROR', 'Invalid JSON ' + this.errors.map(error => error.key + ', '))
        return
      }
      try {
        this.loading = true
        switch (this.type) {
          case 'user':
            await updateSettingUser(this.item.uuid, { setting: this.formData })
            break
          case 'organization':
            await updateSettingOrganization(this.item.uuid, { setting: this.formData })
            break
          default:
            this.$store.commit('message/SHOW_ERROR', 'model is not defined')
            break
        }
        this.$store.commit('message/SHOW_SUCCESS', 'update success')
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e)
      } finally {
        this.$emit('update')
        this.loading = false
      }
      this.dialog = false
    },
  },
}
</script>
<style scoped>
/deep/ .v-input__icon--append .v-icon {
  display: none !important;
}
.json-edit {
  display: flex;
  flex-direction: column;
}
.icon:hover {
  color: azure;
}
</style>
